import React from "react"
import { Link } from "gatsby"

import { motion } from "framer-motion"

import Layout from "../components/layout"

import SEO from "../components/seo"

import Rocket from "../images/skyrocket.png"
import BGWall from "../images/socialWallpaper.png"
import understand from "../images/understand.png"
import planning from "../images/planning@2x.png"
import execute from "../images/execute@2x.png"
import test from "../images/test@2x.png"
import logo from "../images/skytocket-Logo.png"
import logoW from "../images/smLogoblueW.png"

import dmicon from "../images/digital-marketing-icon.png"
import sficon from "../images/sales-funnel-icon.png"
import cmarketing from "../images/cmarketing-icon.png"
import seoicon from "../images/seo-icon.png"
import wdicon from "../images/web-design-icon.png"
import smicon from "../images/social-media-icon.png"

// import serviceWP from "../images/bg-home-image.png"
import headerBG from "../images/headerbg1.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      className="text-center w-100 bg-center bg-cover py-1"
      style={{ backgroundImage: `url(${headerBG})` }}
    >
      <div className="mb-56">
        <motion.img
          src={Rocket}
          alt={""}
          className={"w-1/4 mb-0 w-100 mx-auto z-0"}
          animate={{ y: -105 }}
          transition={{ ease: "easeOut", duration: 4, delay: 4 }}
        />
        <div className="-mt-64 z-10">
          <motion.div
            className=""
            initial={{ opacity: 0, y: null }}
            animate={{ y: 170, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 2, delay: 2 }}
          >
            <div className="grid grid-cols-12 grid-rows-1 relative">
              <motion.img
                src={logo}
                alt="Skyrocket"
                className="col-start-4"
                animate={{ y: -10, rotate: 5 }}
                transition={{ yoyo: Infinity, duration: 6 }}
              />
              <motion.img
                src={logoW}
                alt="skyrocket Digital Marketing"
                className="col-span-4"
              />
            </div>
          </motion.div>
          <div className="w-3/4 mx-auto">
            <motion.p
              className="text-center text-lg my-10"
              initial={{ opacity: 0, y: null }}
              animate={{ y: 120, opacity: 1 }}
              transition={{ ease: "easeOut", duration: 1, delay: 3 }}
            >
              Committed to Helping Your Business Skyrocket, through Creative
              Thinking, Innovation and Custom-Tailored Marketing Strategies.
            </motion.p>
          </div>
          <motion.button
            className="bg-skblue-100 hover:bg-skblue-200 hover:shadow text-white font-bold py-2 px-4 rounded shadow-2xl focus:outline-none focus:shadow-outline"
            initial={{ opacity: 0, y: null }}
            animate={{ y: 120, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 4 }}
          >
            Get Started Now
          </motion.button>
        </div>
      </div>
    </div>
    <div
      style={{ backgroundImage: `url(${BGWall})` }}
      className="text-center bg-center bg-no-repeat bg-cover bg-fixed my-16 border-white"
    ></div>
    <div className="container mx-auto py-3">
      <div>
        <h1 className="font-bold text-center">
          Our Proven Process Gets Results
        </h1>
      </div>
      <div className="flex flex-row flex-wrap justify-center">
        <div className="md:flex-1">
          <img
            src={understand}
            alt={"Process Step 1"}
            className="mb-0 h-32 mx-auto"
          />
          <h3 className="text-center font-medium px-4 py-2 m-2">
            1.
            <br />
            Understand Your Challenges
          </h3>
        </div>
        <div className="md:flex-1">
          <img
            src={planning}
            alt={"Process Step 1"}
            className="mb-0 h-32 mx-auto"
          />
          <h3 className="text-center font-medium px-4 py-2 m-2">
            2.
            <br />
            Plan How To Execute
          </h3>
        </div>
        <div className="md:flex-1">
          <img
            src={execute}
            alt={"Process Step 1"}
            className="mb-0 h-32 mx-auto"
          />
          <h3 className="text-center font-medium px-4 py-2 m-2">
            3.
            <br />
            Execute &amp; Delivery
          </h3>
        </div>
        <div className="md:flex-1">
          <img
            src={test}
            alt={"Process Step 1"}
            className="mb-0 h-32 mx-auto"
          />
          <h3 className="text-center font-medium px-4 py-2 m-2">
            4.
            <br />
            Test Results & Iterate
          </h3>
        </div>
      </div>
    </div>

    <div className="services py-16 bg-gray-200">
      <div className="container mx-auto">
        <h1 className="font-extrabold text-center text-gray-800 font-extrabold text-4xl">
          We Offer A Variety Of Creative Services To Suite Every Need
        </h1>
        <div className="flex flex-wrap justify-evenly">
          <div className="p-4 text-center w-64">
            <img
              src={dmicon}
              alt="digital marketing icon"
              className="h-12 m-auto my-2"
            />
            <h3 className="h-8 text-skblue-100 text-base">
              DIGITAL MARKETING
              <br />
              STRATEGY CONSULTANT
            </h3>
            <p>
              We will evaluate your current marketing strategy and discover the
              new ways for your business to engage with existing customers and
              attract new ones
            </p>
          </div>

          <div className="p-4 text-center w-64">
            <img
              src={sficon}
              alt="sales funnel icon"
              className="h-12 m-auto my-2"
            />
            <h3 className="h-8 text-skblue-100 text-base">
              SALES
              <br />
              FUNNEL
            </h3>
            <p>
              Improve your conversion rates with our custom tailored sales
              funnels, which will allow your business to effortlessly get new
              leads and increased revenue.
            </p>
          </div>

          <div className="p-4 text-center w-64">
            <img
              src={cmarketing}
              alt="retargeting marketing icon"
              className="h-12 m-auto my-2"
            />
            <h3 className="h-8 text-skblue-100 text-base uppercase">
              conversational
              <br />
              marketing
            </h3>
            <p>
              Achieve Higher Conversions and Gain the Trust of Your Customers
              through Targeted Messaging and Intelligent Chatbots
            </p>
          </div>
          <div className="p-4 text-center w-64">
            <img
              src={seoicon}
              alt="digital marketing icon"
              className="h-12 m-auto my-2"
            />
            <h3 className="h-8 text-skblue-100 text-base">
              SEARCH ENGINE
              <br />
              OPTIMIZATION
            </h3>
            <p>
              We help businesses gain top positions in the natural search
              results by building working digital tactics to deliver Higher
              Leads and Conversions.
            </p>
          </div>
          <div className="p-4 text-center w-64">
            <img
              src={wdicon}
              alt="web design icon"
              className="h-12 m-auto my-2"
            />
            <h3 className="h-8 text-skblue-100 text-base">
              WEBSITE
              <br />
              DESIGN
            </h3>
            <p>
              We will help you stand out in the online world with a professional
              website that will give visitors a positive perception of your
              brand
            </p>
          </div>
          <div className="p-4 text-center w-64">
            <img
              src={smicon}
              alt="social media marketing icon"
              className="h-12 m-auto my-2"
            />
            <h3 className="h-8 text-skblue-100 text-base">
              SOCIAL
              <br />
              MEDIA ADS
            </h3>
            <p>
              Social media advertising services, that highlight your brand's
              specific products or services, while helping you drive quality
              traffic to your business and increase your revenue
            </p>
          </div>
        </div>
        <div className="text-center mx-auto">
          <Link to="/services/" alt="">
            <button
              className="bg-skblue-100 hover:bg-skblue-200 hover:shadow text-white font-bold py-2 px-4 rounded shadow-2xl focus:outline-none focus:shadow-outline"
              initial={{ opacity: 0, y: null }}
              animate={{ y: 170, opacity: 1 }}
              transition={{ ease: "easeOut", duration: 0.5, delay: 7 }}
            >
              Learn About Our Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
